<template>
	<footer>
		<div class="position-relative">
			<div class="popover" :hidden="hideInfo">
				<div class="popover-body">{{$t('partnerinfo')}}</div>
			</div>
		</div>
			<div class="row no-gutters footer-links">
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.impressum || links.impressum" target="blank" rel="noopener noreferrer">{{$t('impressum')}}</a>
				</div>
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-if="$store.getters.getAppMandant == 101">{{$t('about_us')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 104">{{$t('about_us_cw')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 105">{{$t('about_us_wc')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 106">{{$t('about_us_zt')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 107">{{$t('about_us_ct')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 109">{{$t('about_us_um')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 111">{{$t('about_us_um')}}</a>
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.about_us || links.about_us" target="blank" rel="noopener noreferrer" v-else-if="$store.getters.getAppMandant == 112">{{$t('about_us_wc')}}</a>
				</div>
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.public_partner_area || links.public_partner_area" target="blank" rel="noopener noreferrer">{{$t('public_partner_area')}}</a>
					<i class="fa fa-info ml-2" role="button" @click="toggleInfo"></i>
				</div>
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.agb || links.agb" target="blank" rel="noopener noreferrer">{{$t('agb')}}</a>
				</div>
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.privacy || links.privacy" target="blank" rel="noopener noreferrer">{{$t('privacy')}}</a>
				</div>
				<div class="col-md-2 col-sm-4 col-6 text-center">
					<a :href="links.translations.find(t => t.lang == $i18n.locale)?.press || links.press" target="blank" rel="noopener noreferrer">{{$t('press')}}</a>
				</div>
			</div>
	</footer>
</template>

<script>
	export default {
		data(){
			return {
				hideInfo: true,
				links: {
					agb: process.env.VUE_APP_FE_101 + "kreuzfahrt-service/agb.html",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_101 + "datenschutz.html",
					press: process.env.VUE_APP_FE_101 + "presse.html",
					impressum: process.env.VUE_APP_FE_101 + "impressum.html",
					about_us: process.env.VUE_APP_FE_101 + "ueber-ehoi.html",
					translations: []
				}
			}
		},
		created(){
			if(this.$store.getters.getAppMandant == 104){
				this.links = {
					agb: process.env.VUE_APP_FE_104 + "algemene-voorwaarden/",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_104 + "privacy/",
					press: "https://blog.cruisewinkel.nl/",
					impressum: process.env.VUE_APP_FE_104 + "bedrijfsgegevens/",
					about_us: process.env.VUE_APP_FE_104 + "over-ons/",
					translations: []
				}
			}else if(this.$store.getters.getAppMandant == 105){
				this.links = {
					agb: process.env.VUE_APP_FE_105 + "algemene-voorwaarden/",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_105 + "bedrijfsgegevens/",
					press: "https://blog.cruisewinkel.nl/",
					impressum: process.env.VUE_APP_FE_105 + "bedrijfsgegevens/",
					about_us: process.env.VUE_APP_FE_105 + "over-ons/",
					translations: [{
						lang: "fr",
						agb: process.env.VUE_APP_FE_105 + "fr/conditions-generales/",
						public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
						privacy: process.env.VUE_APP_FE_105 + "fr/details-de-lentreprise/",
						press: "https://blog.cruisewinkel.nl/",
						impressum: process.env.VUE_APP_FE_105 + "fr/details-de-lentreprise/",
						about_us: process.env.VUE_APP_FE_105 + "fr/a-propos-de-nous/"
					}]
				}
			}else if(this.$store.getters.getAppMandant == 106){
				this.links = {
					agb: process.env.VUE_APP_FE_106 + "algemene-voorwaarden/",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_106 + "gegevensbescherming/",
					press: "https://blog.zeetours.nl/",
					impressum: process.env.VUE_APP_FE_106 + "colofon/",
					about_us: process.env.VUE_APP_FE_106 + "over-zeetours-cruises/",
					translations: []
				}
			}else if(this.$store.getters.getAppMandant == 107){
				this.links = {
					agb: process.env.VUE_APP_FE_107 + "algemene-voorwaarden/",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_107 + "privacyverklaring/",
					press: process.env.VUE_APP_FE_107 + "blog-overzicht/",
					impressum: process.env.VUE_APP_FE_107 + "colofon/",
					about_us: process.env.VUE_APP_FE_107 + "over-ons/",
					translations: []
				}
			}else if(this.$store.getters.getAppMandant == 109){
				this.links = {
					agb: process.env.VUE_APP_FE_109 + "gtc",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_109 + "protección-de-datos",
					press: process.env.VUE_APP_FE_109 + "",
					impressum: process.env.VUE_APP_FE_109 + "",
					about_us: process.env.VUE_APP_FE_109 + "acerca-de-un-mundo-de-cruceros",
					translations: []
				}
			}else if(this.$store.getters.getAppMandant == 111){
				this.links = {
					agb: process.env.VUE_APP_FE_111 + "gtc",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_111 + "proteção-de-dados",
					press: process.env.VUE_APP_FE_111 + "",
					impressum: process.env.VUE_APP_FE_111 + "",
					about_us: process.env.VUE_APP_FE_111 + "",
					translations: []
				}
			}else if(this.$store.getters.getAppMandant == 112){
				this.links = {
					agb: process.env.VUE_APP_FE_112 + "cgv",
					public_partner_area: "https://projects.e-domizil.de/confluence/pages/viewpage.action?spaceKey=PPEH&title=Partnerprogramm+der+e-hoi+GmbH",
					privacy: process.env.VUE_APP_FE_112 + "datenschutz",
					press: process.env.VUE_APP_FE_112 + "",
					impressum: process.env.VUE_APP_FE_112 + "",
					about_us: process.env.VUE_APP_FE_112 + "",
					translations: []
				}
			}
		},
		methods: {
			toggleInfo: function(){
				this.hideInfo = !this.hideInfo
			}
		}
	}
</script>

<style>
	.footer-links > *:not(:last-child){
		border-right: solid 1px #fff;
	}

	footer .popover{
		width: 100%;
		bottom: 1px;
		top: unset;
		max-width: unset;
	}
</style>