// @ts-check

/** @type Record<number,string> */
export const mandantLogo = {
	104: "104",
	105: "105",
	106: "106",
	107: "107",
	109: "109",
	111: "111",
	112: "112"
}

/** @type Record<number,string> */
export const mandantHref = {
	104: "https://www.cruisewinkel.nl",
	105: "https://www.wondercruises.be",
	106: "https://zeetours.nl",
	107: "https://www.cruisetravel.nl",
	109: "https://www.cruisetravel.nl",
	111: "https://www.cruisetravel.nl",
	112: "https://www.wondercruises.fr"
}

/** @type Record<number,string> */
export const mandantAppTitle = {
	104: "cruisewinkel partnerware",
	105: "wondercruises partnerware",
	106: "zeetours partnerware",
	107: "cruisetravel partnerware",
	109: "ummundo partnerware",
	111: "ummundo partnerware",
	112: "wondercruises partnerware"
}

/** @type Record<number,string> */
export const mandantAppClass = {
	104: "mandant-104",
	105: "mandant-105",
	106: "mandant-106",
	107: "mandant-107",
	109: "mandant-109",
	111: "mandant-111",
	112: "mandant-112"
}

/** @type Record<number,string> */
export const mandantTranslations = {
	104: "Cruisewinkel",
	105: "Wondercruises",
	106: "Zeetours",
	107: "Cruisetravel",
	109: "Um mundo",
	111: "Um mundo",
	112: "Wondercruises"
}

/** @type Partial<Record<number,string>> */
const mandantWlvDomain = {
	101: process.env.VUE_APP_WLVPATH_101,
	102: process.env.VUE_APP_WLVPATH_102,
	104: process.env.VUE_APP_WLVPATH_104,
	105: process.env.VUE_APP_WLVPATH_105,
	106: process.env.VUE_APP_WLVPATH_106,
	107: process.env.VUE_APP_WLVPATH_107,
	109: process.env.VUE_APP_WLVPATH_109,
	111: process.env.VUE_APP_WLVPATH_111,
	112: process.env.VUE_APP_WLVPATH_112
}

/** 
 * @param {number} mandant 
 * @returns {string}
*/
export function getWLVDomain(mandant){
	return mandantWlvDomain[mandant] ?? "";
}